<template>
    <div>
        <div v-for="payment in row.item.payments" :key="payment.id">
            {{ payment.amount | priceFormat }}&euro;
            {{ payment.datum | formatDate('DD.MM.YYYY HH:mm') }} ({{ payment.marked_type }})
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>